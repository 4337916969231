import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid, SelectChangeEvent } from '@mui/material';

import LoaderOverlay from 'Components/LoaderOverlay';
import BountyAttachmentManagement from 'Containers/BountyAttachmentManagement';
import BannerInfo from 'Components/BannerInfo';
import TooltipAnchor from 'Components/TooltipAnchor';
import RecommendationsBanner from 'Containers/BannerCreation/RecommendationsBanner';
import HighlightsManagement from 'Containers/BannerCreation/HightlightsManagement';

import { companySettingsSelector, settingsSelector, userDataSelector } from 'Store/settings/selectors';
import {
  createBounty,
  onEditBounty,
  updateLocalBounty,
} from 'Services/bounty/BountyService';
import { attachmentsListSelector } from 'Store/attachments/selectors';
import {
  configurationSelector,
  selectedBountySelector,
} from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';

import { BountyType, BannerType, TargetApp, CompanyType, AttachmentAction } from 'Constants/enums';
import { setDataAction } from 'Store/genericActions';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { GenericReducerName } from 'Constants/genericReducerName';
import { bountyRefreshCountSelector } from 'Store/bounty/selectors';
import { BANNERS_ROUTE } from 'Constants/routes';
import { formatBounty } from 'Utils/bountyCreation';
import { bountySchema, validateBounty } from 'Utils/validation/bounty';
import { notificationToast } from 'Utils/notificationToaster';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { getExpiryComponents } from 'Utils/bounty';
import { LocationsService } from 'Services/LocationsService';
import { authUserCompanyIdSelector } from 'Store/auth/selectors';
import { ThunkDispatchType } from 'Types/redux.types';
import { ClientLocation } from 'Types/clientLocation.interface';
import { SelectOption } from 'Types/option.interface';
import { BannerForm } from 'Types/banner.interface';
import { UseCases } from 'Utils/globalPermissions';
import { getApplicationConfig } from 'Utils/appConfig';
import { addAttachments, eraseAttachments, setInlineAttachments } from 'Store/attachments/actions';
import { DEFAULT_CATEGORY_HIGHLIGHTS_COUNT } from 'Constants/banners';
import { Attachment } from 'Types/attachment.interface';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const ClientBannerCreation = () => {
  const { useCase } = getApplicationConfig();
  const selectedBounty = useSelector(selectedBountySelector) || {};
  const userData = useSelector(userDataSelector)?.data || {};
  const settings = useSelector(settingsSelector) || {};
  const refreshCount = useSelector(bountyRefreshCountSelector) || 0;
  const attachments: Attachment[] = useSelector(attachmentsListSelector) || [];
  const configuration = useSelector(configurationSelector);
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const canSeeLocations = useCase === UseCases.shop && currentOrgType !== CompanyType.Merchant;
  const companyId = useSelector(authUserCompanyIdSelector);

  const [locations, setLocations] = useState<SelectOption[]>([]);
  const [rawLocations, setRawLocations] = useState<ClientLocation[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [expiration, setExpiration] = useState<{ days: number; hours: number; minutes: number } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const intl = useIntl();

  const isEditMode = !!(selectedBounty && Object.keys(selectedBounty).length && !configuration.duplicate);

  const defaultLocationFilters = useMemo(() => (
    locations?.[0]?.value ? [locations?.[0]?.value] : []
  ), [locations]);

  useEffect(() => {
    if (canSeeLocations && companyId) {
      fetchClientLocations();
    }
  }, [companyId]);

  useEffect(() => () => { dispatch(eraseAttachments()); }, []);

  useEffect(() => {
    if (Object.keys(selectedBounty).length) {
      const expirationData = getExpiryComponents(selectedBounty, BountyType.Banner);
      setExpiration(expirationData);
    }
  }, [selectedBounty?.id]);

  const fetchClientLocations = async () => {
    setIsLoading(true);
    try {
      const [{ list: storeLocations }, { list: usStates }] = await Promise.all([
        LocationsService.getLocations(companyId),
        LocationsService.getUSstates(),
      ]);
      setRawLocations(storeLocations);
      setLocations(storeLocations.map(({ id, name }) => ({ label: name, value: id })));
      setStates(usStates.map(({ key, value }) => ({ label: value, value: key })));
    } catch {
      setLocations([]);
      setStates([]);
    } finally {
      setIsLoading(false);
    }
  };

  const onDismiss = () => {
    formik.resetForm({});
    dispatch(closeDrawer());
  };

  const handleCreateBounty = (values: BannerForm, { setSubmitting }: FormikHelpers<BannerForm>) => {
    const payload = { values, userData, settings };
    const bounty = formatBounty(payload);
    const isRecommendation = bounty?.banner?.bannerType === BannerType.Recommendations;

    if ([BannerType.Highlights, BannerType.Recommendations].includes(values?.banner?.bannerType)) {
      const filteredAttachments = attachments?.filter((attachment) => attachment?.filename || attachment?.action?.bountyIds);
      dispatch(setInlineAttachments(filteredAttachments));
    }

    dispatch(createBounty(bounty, [], isRecommendation))
      .then(() => {
        dispatch(setDataAction(GenericReducerName.UserStreams, GenericReducerProp.RefreshBountyDetails, refreshCount + 1));
        dispatch(closeDrawer());
        formik.resetForm({});
        navigate(BANNERS_ROUTE);
      })
      .catch(() => setSubmitting(false));
  };

  const handleEditBounty = (values: BannerForm, { setSubmitting }: FormikHelpers<BannerForm>) => {
    const payload = { values, userData, settings };
    const newBounty = {
      ...selectedBounty,
      ...formatBounty(payload),
    };
    const isRecommendation = newBounty?.banner?.bannerType === BannerType.Recommendations;

    if ([BannerType.Highlights, BannerType.Recommendations].includes(values?.banner?.bannerType)) {
      const filteredAttachments = attachments?.filter((attachment) => attachment?.filename || attachment?.action?.bountyIds);
      dispatch(setInlineAttachments(filteredAttachments));
    }

    dispatch(onEditBounty({ newBounty, oldBounty: selectedBounty, ignoreAttachUpload: isRecommendation }))
      .then((editedBounty) => {
        dispatch(setDataAction(GenericReducerName.UserStreams, GenericReducerProp.RefreshBountyDetails, refreshCount + 1));
        dispatch(updateLocalBounty(editedBounty));
        formik.resetForm({});
        dispatch(closeDrawer());
      })
      .catch(() => setSubmitting(false));
  };

  const onSubmit = (values: BannerForm, handlers: FormikHelpers<BannerForm>): void => {
    const customErrors = validateBounty(values, attachments);

    if (customErrors) {
      notificationToast.error(intl.formatMessage({ id: customErrors.message }, customErrors.values));
      return;
    }

    if (canSeeLocations && !values?.banner?.locationFilters?.length) {
      handlers.setSubmitting(false);
      handlers.setFieldError('banner.locationFilters', 'validate.required_field');
      return;
    }

    const fn = isEditMode ? handleEditBounty : handleCreateBounty;
    fn(values, handlers);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selectedBounty,
      type: BountyType.Banner,
      days: expiration?.days || '',
      hours: expiration?.hours || '',
      minutes: expiration?.minutes || '',
      banner: {
        bannerType: selectedBounty?.banner?.bannerType || BannerType.Hero,
        targetApp: TargetApp.Shop,
        userAuthStatus: selectedBounty?.banner?.userAuthStatus || '',
        priority: selectedBounty?.banner?.priority || '',
        weight: selectedBounty?.banner?.weight || '',
        ...(canSeeLocations ? ({
          countryFilters: selectedBounty?.banner?.countryFilters?.US?.stateCodes
            ? selectedBounty.banner.countryFilters.US.stateCodes.split(',')
            : [],
          locationFilters: selectedBounty?.banner?.locationFilters
            ? Object.keys(selectedBounty?.banner?.locationFilters)
            : defaultLocationFilters,
        }) : {}),
      },
    },
    validationSchema: bountySchema,
    onSubmit,
  });
  const bannerType = formik?.values?.banner?.bannerType;

  useEffect(() => {
    if (!formik?.values?.banner?.locationFilters?.length) {
      setSelectedCompanyId('');
    } else {
      const [firstLocation] = formik?.values?.banner?.locationFilters;
      const locationInfo = rawLocations.find((item) => item.id === firstLocation);
      setSelectedCompanyId(locationInfo?.companyId || '');
    }
  }, [formik?.values?.banner?.locationFilters, rawLocations]);

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>) => {
    if (name === 'banner.bannerType') {
      dispatch(eraseAttachments());

      if (value === BannerType.Highlights) {
        const action = { actionType: AttachmentAction.Search };
        dispatch(addAttachments(Array(DEFAULT_CATEGORY_HIGHLIGHTS_COUNT).fill({ action })));
      }
    }

    formik.setFieldValue(name, value);
  };

  const renderBoldChunks = (chunks: ReactNode[]) => <b>{chunks}</b>;
  const renderBreak = () => <br />;

  return (
    <div className={styles.containerWithAttachments}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <h4 className={styles.title}>{intl.formatMessage({ id: 'label.bannerSettings' })}</h4>
          <BannerInfo
            locations={locations}
            states={states}
            formik={formik}
            handleChanges={handleChanges}
            canSeeLocations={canSeeLocations}
          />
          <CustomModalButtons
            disabled={formik.isSubmitting}
            onDismiss={onDismiss}
            onSubmit={formik.handleSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {bannerType === BannerType.Hero && (
            <BountyAttachmentManagement
              selectedCompanyId={selectedCompanyId}
              bounty={selectedBounty}
              titleTooltip={(
                <TooltipAnchor
                  translationKey="hint.bannerSlides"
                  values={{
                    br: renderBreak,
                    b: renderBoldChunks,
                  }}
                />
              )}
              title={intl.formatMessage({ id: 'label.bannerSlides' })}
            />
          )}
          {bannerType === BannerType.Recommendations && (
            <RecommendationsBanner
              selectedCompanyId={selectedCompanyId}
              bounty={selectedBounty}
            />
          )}
          {bannerType === BannerType.Highlights && (
            <HighlightsManagement selectedCompanyId={selectedCompanyId} bounty={selectedBounty} />
          )}
        </Grid>
      </Grid>
      {isLoading && <LoaderOverlay />}
    </div>
  );
};

export default ClientBannerCreation;
