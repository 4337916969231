export const RESPONSES = '/api/v1/responses';
export const REQUESTS = '/api/v1/requests';
export const CLIENTS = '/api/v1/clients';
export const COMPANIES = '/api/v1/companies';

export const EMPLOYEES = '/api/v1/companies/:companyId/employees';
export const APPLICANTS = '/api/v1/companies/:companyId/applicants';
export const USER_APPLICANTS = '/api/v1/users/:userId/applicants';

export const USERS_DATA = '/api/v1/users/:userId/data';
export const USER_ACCOUNT_API = '/api/v1/users/:userId/account';
export const USER_TRANSACTIONS_API = '/api/v1/users/:userId/transactions';
export const NOTIFICATION_API_ROUTE = '/api/v1/notify';
export const LOOKUPS_API_ROUTE = '/api/v1/lookups/:lookuptype';
export const SKILLS_INDUSTRIES_API = '/api/v1/open/industries';
export const SPECIAL_PROGRAMS_API = '/api/v1/special_programs';
export const COUNTRIES_ROUTE = '/api/v1/system/countries';
export const AUDIT = '/api/v1/system/audit/log';

export const SYSTEM_INFO_BY_COUNTRY_ROUTE = '/api/v1/system/countries/:countryCode';
export const POST_SHARE_URL = '/api/v1/bounties/:bountyId/shares';
export const BOUNTY_FLAGS_ROUTE = '/api/v1/bounties/:bountyId/flags';

export const USER_COMMENTS = '/api/v1/users/:userId/comments';
export const USER_META_ROUTE = '/api/v1/users/:userId/meta';
export const USER_SALE_STATISTICS_ROUTE = '/api/v1/users/:userId/sales/statistics';
export const USER_POLICY_ROUTE = '/api/v1/users/:userId/policy';
export const REPORTS_LIST = '/reports/meta';
export const INVITE_LINKS = '/api/v1/bounties/:bountyId/inviteLinks/:linkId';

export const INVITES = '/api/v1/invites';
export const INVITE_INFO = '/api/v1/open/invites/:inviteId/info';

export const LOOKUPS = '/api/v1/lookups';
export const LOCATIONS_API = '/api/v1/clients/:companyId/locations';
export const LOCATION_DETAILS_API = '/api/v1/clients/:companyId/location';
export const OPEN_US_STATES_API = '/api/v1/open/lookups/US_STATE';
export const OPEN_PRODUCTS_API = '/api/v1/open/products';
export const LANGUAGES_API = '/api/v1/lookups/LANGUAGES';
export const WRITE_MESSAGE_ROUTE = '/api/v1/chats/:roomId/messages';
export const CHAT_CMD_ROUTE = '/api/v1/chats/:roomId/commands';
export const MARK_MSG_AS_READ_ROUTE = '/api/v1/chats/:roomId/messages/read';
export const CREATE_CHAT_ROUTE = '/api/v1/chats';
export const CREATE_CHAT_AI_BOT_ROUTE = '/api/v1/chats/ai-bot';
export const USER_CHATS_ROUTE = '/api/v1/users/:userId/chats';
export const COMPANY_SUPPORT_CHATS_ROUTE = '/api/v1/clients/:companyId/supportChats';
export const PRODUCT_CHEMICALS_API = '/api/v1/clients/:companyId/productChemicals';
export const PRODUCT_CUSTOM_CHEMICALS_API = '/api/v1/clients/:companyId/productCustomChemicals';
export const UNASSIGN_MEMBER_API = '/api/v1/users/:userId/unassign';
export const USER_AGREEMENT_API = '/api/v1/users/:userId/agreement';
export const UPDATE_USER_API = '/api/v1/users/:userId/update';
export const GET_USER_CURRENCIES = '/api/v1/users/:userId/currencies/statistics';
export const GET_CURRENCY_REPORT_API = '/api/v1/currencies/:code/report';
export const CLIENT_ORDERS_ROUTE = `${CLIENTS}/:clientId/orders`;
export const CLIENT_DETAILS_API = `${CLIENTS}/:clientId`;
export const CLIENT_LOCATIONS_API = `${CLIENTS}/:clientId/locations`;
export const UPDATE_CLIENT_LOCATION_API = `${CLIENTS}/locations/:clientId`;
export const CLIENT_ORDER_DETAILS_ROUTE = `${CLIENTS}/:clientId/orders/:orderId`;
export const CLIENT_SETTINGS_AUDIT_LOG_ROUTE = `${CLIENTS}/:clientId/settings/shopSettings/audit_logs`;
export const USER_ORDER_DETAILS_ROUTE = `/api/v1/users/:userId/orders/:orderId`;

export const GOOGLE_ANALYTICS_TOKEN_API = '/api/v1/google/:companyId/analytics_info';
export const EVENTS_ROUTE = '/api/v1/events/:action';

export const PLACE_ORDER_API = '/api/v1/shoppingcart/:cartId/order';
export const ORDER_SUMMARY_API = '/api/v1/shoppingcart/:cartId/summary';
export const COMPANY_ORDER_ALERT = '/api/v1/alerts/companies/:companyId/order/:bountyId';
export const UPDATE_ORDER_STATUS_ROUTE = '/api/v1/open/orders/:orderId/status/:status';
export const USER_MERCHANT_ORDERS_ROUTE = '/api/v1/users/:userId/merchant/orders';
export const USER_ORDERS_TMP_ROUTE = '/api/v1/users/:userId/orders/tmp';
export const USER_SUBORDERS_ROUTE = '/api/v1/open/:userId/suborders/:orderId';

export const RANKINGS_API = '/api/v1/requests/rankings_fetch';
export const DOCUMENTS_SEARCH_API = '/api/v1/system/doc-search';
export const GET_DOCUMENT_API = '/api/v1/system/doc-get';
export const GET_FOLLOWERS_API = '/api/v1/users/:userId/followers';
export const GET_FOLLOWING_API = '/api/v1/users/:userId/following';

export const GET_DEFAULT_EMAIL_TEMPLATE = '/api/v1/alerts/email/defaultTemplate';
export const GET_EMAIL_TEMPLATE = '/api/v1/alerts/:companyId/email/template';
export const UPDATE_EMAIL_TEMPLATE = '/api/v1/alerts/:companyId/email/template';
export const TEST_EMAIL_TEMPLATE = '/api/v1/alerts/:companyId/email/test';
export const SETTINGS_EMAIL_TEMPLATE = '/api/v1/alerts/:companyId/email/settings';

export const GET_DOWNLOAD_REPORT = '/download/report';

export const CANCEL_CURRENCY = '/api/v1/currencies/:code/cancel';
export const ACCOUNT_CURRENCY = '/api/v1/account/:code/currency';
export const ACCOUNT_CURRENCY_EXCHANGE = '/api/v1/account/currency/:code/exchange';
export const ACCOUNT_CURRENCIES = '/api/v1/account/currencies';
export const ACCOUNT_AVAILABLE_AMOUNT = '/api/v1/account/amount_tmp';

export const SHOP_SETTINGS = '/api/v1/open/settings/shopSettings';
export const CLIENT_SHOP_SETTINGS = `${CLIENTS}/:clientId/settings/shopSettings`;

export const GET_CARDS = '/api/v1/creditCards';

export const CATEGORIES_OPEN_ROUTE = '/api/v1/open/categories/:bountyType';
export const CATEGORIES_ROUTE = '/api/v1/categories';
export const CATEGORY_DETAILS_ROUTE = '/api/v1/categories/:categoryId';
export const FILTERS_OPEN_ROUTE = '/api/v1/open/filters/:bountyType';

export const GET_RESERVATIONS = '/api/v1/open/reservations';
export const GET_RESERVATIONS_STATISTICS = '/api/v1/clients/:clientId/reservations/statistics';
export const USER_RESERVATIONS = '/api/v1/users/reservations';
export const GET_RESERVATIONS_AVAILABILITY = '/api/v1/open/reservations/availability';

export const GET_ALLEAVES_TERMINALS = '/api/v1/alleaves/:clientId/terminals';

export const TOKENS_CURRENCY_EXCHANGE = '/api/v1/open/tokens/:currencyFrom/exchange';

export const GET_VENDOR_POLICY = '/api/v1/open/vendor/:userId/policy';

export const GET_STRIPE_CONNECT_API = '/stripe/connect';
export const GET_STRIPE_ACCOUNT_API = '/api/v1/stripe/account';

export const GET_CAMPAIGNS_API = '/api/v1/open/campaigns';
export const GET_CAMPAIGNS_SECTIONS_API = '/api/v1/open/campaigns/sections';
export const GET_BOUNTY_INFO_API = '/api/v1/open/bounties/:bountyId/info';
export const GET_BOUNTY_STATISTICS_API = '/api/v1/bounties/:bountyId/statistics';

export const UPDATE_USER_INFO_API = '/api/v1/users/:userId/update_user';
export const GET_STREAM_BY_LIST_ID_API = '/api/v1/open/user/stream/:listId';
export const GET_USER_GROUP_STREAM_API = '/api/v1/open/user/stream/group';
export const GET_USER_STREAM_API = '/api/v1/open/user/stream/personal';
export const GET_USER_PUBLIC_STREAM_API = '/api/v1/open/user/stream/public';

export const MEMBER_SUPPORT_GROUP_API = '/api/v1/groups/support/members/:memberId';

export const GET_COLOR_SCHEMES_API = '/api/v1/bounties/colorSchemes';

export const MERCHANT_SHIPPING_FEE_API = '/api/v1/merchant/:userId/shippingFee';

export const ADDRESS_DETAILS_API = '/api/v1/users/:userId/addresses/:addressId';
